<template>
  <div class="mp__empty-sec">
    <div class="mp__empty-sec-wrapper">
      <img :src="errorImage" :alt="errorType" width="auto" height="auto" />
      <div class="empty-sec-title">
        {{ $t(`my_programmes.empty.${errorType}.title`) }}
      </div>
      <div class="empty-sec-subtitle">
        {{ $t(`my_programmes.empty.${errorType}.subtitle`) }}
      </div>
      <Button
        class="btn empty-sec-button"
        @click="$router.push({ name: 'course-discovery' })"
      >
        {{ $t(`my_programmes.empty.button`) }}
      </Button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tabIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    errorType() {
      switch (this.tabIndex) {
        case 0:
          return "in_progress";
        case 1:
          return "starting_soon";
        case 2:
          return "archived";
        default:
          return "in_progress";
      }
    },
    errorImage() {
      switch (this.tabIndex) {
        case 0:
          return require("@/assets/images/not-found/in-progress-not-found.svg");
        case 1:
          return require("@/assets/images/not-found/starting-soon-not-found.svg");
        case 2:
          return require("@/assets/images/not-found/archived-not-found.svg");
        default:
          return require("@/assets/images/not-found/in-progress-not-found.svg");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.mp__empty-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;

  img {
    margin-bottom: 40px;
  }

  .empty-sec-title {
    @include button-label;
    letter-spacing: 0.25px;
    text-align: center;
    color: $brand-neutral-900;
    text-transform: none;
  }

  .empty-sec-subtitle {
    @include button-label;
    font-weight: 400;
    text-align: center;
    color: $brand-neutral-900;
    margin-top: 10px;
    margin-bottom: 36px;
    letter-spacing: 0.25px;
    text-transform: none;
  }

  .empty-sec-button {
    @include button-label;
    text-transform: uppercase;
    padding: 10px;
    width: 260px;
    border-radius: 25px;
    background: $brand-primary-50;
    color: $mylearning_empty_sec_button;
    border: 2px solid $mylearning_empty_sec_button;
    margin: auto;

    &:hover {
      color: $mylearning_empty_sec_button !important;
      border: 2px solid $mylearning_empty_sec_button !important;
      background: $brand-primary-50 !important;
    }
  }
}
</style>
